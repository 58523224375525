////////////////////////////////////////////////////
.dc {
    display: block;
}

.dcNavCategories
{
	margin-bottom: $baseGap*2;
	
	a
	{
		margin-right: $baseGap;
		margin-bottom: $baseGap;

		&.active
		{
			background:$secondary;
			color:$primary;
		}
    }
    
    .title
    {
        font-weight:bold
    }

    .dcSubCategories {
        transition: 300ms ease-in-out;
        .dcCategoryItem {
            padding-left: 1rem;
        }
    }

    .dcCategoryItem {
        position: relative;
        display: block;
        opacity: 1;
        visibility: visible;
        transform: none;
        transition: 300ms ease-in-out;
        max-height: 1000px;
        cursor:pointer;
       
        
        label {
            position: relative;
            display: block;
            margin-bottom: .5rem; 
            cursor:pointer;
        }

        &.hide {
            max-height: 0;
            transform:translate(0, -10px);
            opacity: 0;
            visibility: hidden;
        }
    }

    .showButton {
        position: relative;
        display: inline-block;
        color: $primary;
        cursor: pointer;
        border-top: 1px solid $dark;
        padding-top: rem(5px);
        transition: 300ms ease-in-out;

        &:before {
            position: relative;
            display: inline-block;
            margin-right: .5rem;
        }

        &.more {
            &:before {
                content: '+';
            }

            &:after {
                content: 'Show more';
            }
        }

        &.less {
            &:before {
                content: '-';
            }

            &:after {
               content: 'Show less';
            }
        }
    }
}

.dcOverviewItem
{
	position: relative;
    text-align:left;
    margin-bottom: $baseGap*2;

    .title, h2.title
    {
        a
        {
            text-decoration: none;
        }
        font-size:$h5Size;
        display: block;
        margin-top: $baseGap;
        margin-bottom: 0;
    }
    .dcCoverImage
    {
        overflow: hidden;
        display: block;
        border: none;
        position: relative;
        padding-bottom: 75%;

        img
        {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            margin-bottom: 0;
        }
    }

    .excerpt {
        display: block;
        margin-bottom: rem(20px);
    }
    time
    {
        font-size:rem(12px); 
        transform:translateY(-100%);
        display: block;
        background: rgba($dark, 0.5);
        color:$light;
        padding:0 rem(5px);
    }

    footer
    {
        padding-bottom: $baseGap;

        hr
        {
            margin:$baseGap 0;
        }
        p
        {
            margin-bottom: 0;
        }
    }
}
.dcCategoryList
{
    text-transform: uppercase;
    font-size:rem(12px);
    display: flex;

    a
    {
        text-decoration: none;
        position: relative;

        &:after
        {
            position: absolute;
            height:rem(2px);
            width: 100%;
            content:"";
            display: inline-block;
            left: 0;
            bottom: -3px;
            background: $medium;
        }
    }

    li:not(:last-child)
    {
        &:after
        {
            content:"/";
            margin:0 rem(5px);
        }
    }

    .divider
    {
        color:$secondary;
    }
}

article.dcOverviewItem {
    display: none;
    
    &.show {
        display: block;
    }

	&.blog
	{
        text-align: left;
		time
		{
			display: block;
		}
	}
}
// DC Pagination
html {
    scroll-behavior: smooth;
}

#dcArticles {
    scroll-margin: 20vh;
}

#dcPagination {
    display: flex;
    flex-wrap: wrap;
    
    li {
        margin-bottom: .5rem;

        &:not(:last-of-type) {
            margin-right: .5rem;
        }

        button {
            border: none;
            background: $dark;
            color: $light;
            padding: 1rem;
            cursor: pointer;
            transition: 300ms all;
            display:inline-block;
        }

        &:hover, &.active, &:focus {
            button {
                background: lighten($dark, 20%);
            }
        }
    }
}

.dcArticleShare 
{
    display: flex;
    margin-bottom: 0;
    font-size:0.6rem;
    

    li
    {
        margin-right: $baseGap;
        background: transparent;
        padding: 0;
    }
}

[data-dc-template] {display:none}