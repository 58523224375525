// GLOBAL SETTINGS
//////////////////////////////////////////////

$break: large; // Breakpoint – switch to Desktop version

// NAVIGATION
//////////////////////////////////////////////

$navBg: $light; // Navigation Background
$navColor: $dark; // Navigation Link Color

@mixin navButton {
	// Default Link Styles
	padding: 1em rem($baseGap);
	text-decoration: none;
	color: $light;
	@include breakpoint($break, max){
		border-bottom:1px solid lighten($primary, 10%);
	}
}
%buttonHover {
	// Link Hover
	@include breakpoint($break){
		color: $light;
		background-color: $primary;
	}
}
%buttonActive {
	// Link Active
	color: $light;
	background-color: $secondary;
}
%prevIcon {
	// Icon to previus Submenu
	font-family: $iconFont;
	content: map-get($iconMap, angle-right);
	transform: rotate(180deg);
	font-size: rem(16px);

	// @include breakpoint($break) {
	// 	transform:rotate(90deg);
	// }
}
%nextIcon {
	// Icon to next Submenu
	font-family: $iconFont;
	content: map-get($iconMap, angle-right);
	font-size: rem(14px);

	@include breakpoint($break) {
		transform:rotate(90deg);
	}
}
$closeIcon: map-get($iconMap, times); // Icon to close Submenu

// Quickbar
//////////////////////////////////////////////

$barHeight: 50px; // height (in px)
$barPos: top; // position (top, bottom)
$barHide: true; // if bar hides on scroll (true, false)

$barBg: $dark; // background-color
$barColor: $light; // children colors – texts and burgerbutton

//////////////////////////////////////////////
// NAVIGATION FUNCTIONALITY
//////////////////////////////////////////////

@import "../modules/ruffnav";

// Navigation Styles
////
@include pageNavi {
	background-color: $primary;
	z-index: 1001;

	nav {
		@include ul {
			padding-bottom:5rem;
			&.navi {
				li {
					@include navButtons {
						@include navButton;
					}
					@include hasSub {
						@include sub {
							background: darken($primary, 10%);

							&:before {
								padding: 1em rem($baseGap);
								background-color: darken($navBg, 6%);
							}
						}
					}
					@include navBack {
						background:$secondary;
						transition: all .3s ease-in-out;
					}
				}
			}
		}
	}
}


// Navigation Desktop Styles
////
@include breakpoint($break) {
	@include desktopVersionFunctionality;

	#navigation {
		background:transparent;
		padding:rem(60px) 0 0 0;
		transition: all .3s ease-in-out;
		z-index:99;
		position:absolute;
		font-size: rem(16px);
		max-width: 100%;
		nav {
			&.flexbox{
				margin-left: auto;
				margin-right: auto;
				max-width: rem($rowMaxWidth);
				width: 100%;
				position: relative;
				display: flex;
				flex: 0 1 auto;
				flex-wrap: wrap;
				flex-direction: row;
				justify-content: flex-end;
				align-items: stretch;
				padding: 0 $baseGap;
				ul{
					&.navi{
						transition: all .3s ease-in-out;
						width: 66%;
						border-top:1px dashed $light;
						li{
							&.startseite{
								display:none;
							}
							span{
								transition: all .3s ease-in-out;
							}
						}
						@include breakpoint($break){
							width:79%;
							li{
								font-size: rem(15px);
								a, span{
									padding:1em 8px;
								}
							}
						}
						@include breakpoint(full){
							width: 68%;
							li{
								font-size: 1rem;
								a, span{
									padding:1em $baseGap;
								}
							}
						}
					}
				}			  
			}
			ul {
				padding-bottom:0;
				&.navi {
					li {
						&.hasSub {
							&.events{
								.sub{
									transform:translateX(0);
									left:0;
									@include breakpoint(monster){
										transform:translateX(-50%);
										left:50%;
									}
								}
							}
							.sub {
								border-left:1px dashed $light;
								border-top:1px dashed $light;
								width:rem(450px);
								li{
									border-bottom:1px dashed $light;
									border-right:1px dashed $light;
									height:100%;
									a,span{
										padding:$baseGap;
									}
									&:not(:first-child):not(:nth-child(2)){
										width:50%;
										float:left;
										&.kanutour, &.kanuausflugMargarethenschlucht, &.merkzettel{
											width:100%;
											text-align: center;
										}
									}
									&:nth-child(2){
										background:darken($primary, 20%);
										text-align: center;
									}
									
								}
							}
						}
						&.navBack {
						}
					}
				}
			}
		}
		&.in{
			background: darken($primary, 10%);
			position:fixed;
			top:0;
			nav{
				&.flexbox{
					ul{
						&.navi{
							width: 100%;
							li{
								&.startseite{
									display:block;
								}
							}
						}
					}
				}
			}
		}
		@include breakpoint(monster){
			top:60px;
		}
	}
}

// Quickbar Styles
////
#quickbar {
	background-color: $barBg;
	color: $barColor;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: rem($baseGap);
	z-index: 1000;
	.phone{
		&:before{
			@extend .icon;
			@extend .icon-phone;
			padding-right:.5rem;
		}
	}

	@include breakpoint($break) {
		display: none;
	}
}

//////////////////////////////////////////////
// naviAdd
//////////////////////////////////////////////
.naviAdd {
	li {
		a{
			color:$primary;
			&:hover, &.active{
				color:$secondary;
			}
		}
		@include breakpoint($break, max){
			a{
				padding:$baseGap;
				display:block;
			}
			&:not(:last-child){
				border-bottom:1px solid darken($alert, 10%);
			}
		}
	}
	&.notMobile{
		@include breakpoint($break, max){
			display:none;
		}
	}
}