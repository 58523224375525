* {
  margin: 0;
  padding: 0;

  &,
  &:before,
  &:after {
    box-sizing: inherit;
  }
}

::selection {
}
::-moz-selection {
}

// DO NOT set font-size and line-height here!
// Adjust $baseFontSize and $baseLineHeight in config
html {
  background: lighten($alert, 10%);
  box-sizing: border-box;
  color: $dark;
  font-family: $mainFont;
  font-size: 100% * ($baseFontSize / 16px);
  hyphens: manual;
  line-height: rem($baseLineHeight);
  scroll-behavior: smooth;
  @include breakpoint(monster){
    padding-top:50px;
    padding-bottom:50px;
  }

  /* disable text resize in landscape. e.g. on iphone */
  text-size-adjust: none;
}

body {
  line-height: rem($baseLineHeight);
  opacity: 1 !important;
  overflow-anchor: none;
  background:$light;
  @include breakpoint(monster){
    max-width: rem(1920px);
    margin: 0 auto;
  }

  &:not(.javascript) * {
    transition: none !important;
  }
}

iframe,
[data-iframe] {
  border: none;
  width: 100%;
}

address {
  font-style:normal;
}

/**
 * Headlines
 */

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  @extend .marginBottom;
  display: block;
  text-rendering: optimizeLegibility;
  hyphens: auto;
  line-height:130%;
  text-transform: uppercase;
  font-size: rem(16px);
  line-height: rem(24px);
  font-weight: normal;
  letter-spacing: 5px;
  display:block;
  width:100%;
  span{
    display:block;
    font-family: $displayFont;
    font-weight: bold;
    text-transform: none;
    letter-spacing: 0;
    color:$secondary;
  }

  /* Use this to let headlines break in Chrome Desktop
	body.platformWindows.chrome &, body.platformLinux.chrome & {
		word-break: break-all;
	}
	*/
}

h1,
.h1 {
  span{
    font-size: rem(32px);
    line-height: rem(40px);
    @include breakpoint(medium){
      font-size: rem(52px);
      line-height: rem(60px);
    }
  }
}

h2,
.h2 {
  span{
    font-size: rem(28px);
    line-height: rem(36px);
    @include breakpoint(medium){
      font-size: rem(48px);
      line-height: rem(56px);
    }
  }
}

h3,
.h3 {
  span{
    font-size: rem(26px);
    line-height: rem(34px);
    @include breakpoint(medium){
      font-size: rem(42px);
     line-height: rem(50px);
    }
  }
}

h4,
.h4 {
  span{
    font-size: rem(24px);
    line-height: rem(32px);
    @include breakpoint(medium){
      font-size: rem(38px);
      line-height: rem(46px);
    }
  }
}

h5,
.h5 {
  span{
    font-size: rem(32px);
    line-height: rem(40px);
  }
}

h6,
.h6 {
  span{
    font-size: rem(28px);
    line-height: rem(36px);
  }
}

/**
 * Links
 */
a {
  color: $secondary;
  word-wrap: break-word;
  transition: all .3s ease-in-out;

  &:hover {
    color: $primary;
    text-decoration: none;
  }
  &:focus {
    color: darken($secondary, 50%);
  }
  &:active {
    color: darken($secondary, 30%);
  }

  img {
    border: none;
  }

  &[href^="tel"] {
    color: inherit;
    text-decoration: underline;
  }
}

a:not(.btn):focus,
[tabindex]:focus {
  outline: $outline;
  outline-offset: rem(5px);
}

hr,
.hr {
  display: flex;
  background: $medium;
  border: none;
  clear: both;
  height: rem(1px);
  margin: 4rem 0;
  flex-basis: 100%;
}

ul,
ol {
  list-style: none;

  &.styledList {
    li {
      padding-left:1rem;
      border-left:10px solid darken($alert, 5%);
      margin:1rem 0;
    }

    ul {
      margin-left: 1rem;
      margin-bottom: 0;
    }
  }
  &.float{
    @extend .clearfix;
    li{
      float:left;
      background:darken($alert, 5%);
      margin:.5rem 1rem .5rem 0 !important;
      border-left:0 !important;
      padding:.5rem !important;
      display:inline-block;
    }
  }
}

dl {
  &.styledList {
    @include pie-clearfix;

    dt,
    dd {
      display: block;
      background: lighten($alert, 5%);
      padding: rem(15px);
    }

    dt {
      padding-bottom: 0;
      font-weight: bold;
    }

    dd {
      padding-top: 0;

      + dt {
        margin-top: rem(10px);
      }

      + dd {
        margin-top: rem(-15px);
      }
    }
  }

  &.floatList {
    @include pie-clearfix;

    dt,
    dd {
      display: block;
      vertical-align: top;
      float: left;
    }

    dt {
      padding-right: 0.5rem;
      width: 40%;
      clear: left;
    }

    dd {
      width: 60%;
    }
  }
}

ol {
  &.styledList
  {
    counter-reset: item;
  
    > li {
      counter-increment: item;
  
      &:before {
        content: counter(item) ". ";
      }
    }
    ol {
      counter-reset: item;
      margin-bottom: 0;
  
      > li {
        &:before {
          margin-right: rem(5px);
        }
      }
    }
  }
}

/*
 * responsive images
 */

img {
  vertical-align: bottom;
  float: none;
  height: auto;
  max-width: 100%;
  width: 100%;

  &[src^='http://cms.'], &[src^='https://cms.'] {
    max-width: none;
    width: auto;
  }

  // [data-rel] &,
  // .lightbox-image & {
  //   margin-bottom: 0;
  // }

  &[data-src] {
    @extend %lazyloading;
  }
}

/**
 * Tables
 */
main {
  table {
    display: table;
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
    text-align: left;
    font-size: rem($baseFontSize);

    @include breakpoint(small, max) {
      overflow-x: auto;
      display: block;
    }

    caption {
      text-align: left;
      padding: rem($baseGap) rem($baseGap) rem(25px);
      display: table-caption;
      font-weight: bold;
      font-size: 1.2rem;
    }

    thead {
      border-collapse: collapse;
      display: table-header-group;
    }

    tbody {
      width: 100%;
      overflow-x: scroll;
      display: table-row-group;

      tr {
        padding: rem($baseGap) 0;

        &:not(:last-child) {
          border-bottom: rem(1px) solid $medium;
        }
      }
    }

    tr {
      display: table-row;
    }

    th,
    td {
      padding: rem($baseGap);
      display: table-cell;
      vertical-align: top;
    }

    @include breakpoint(small, max) {
      td {
        min-width: 50vw;
      }
    }
  }
}