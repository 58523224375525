.marginBottom {
  margin-bottom: rem($baseLineHeight);

  &.larger {
    margin-bottom: rem($baseLineHeight*2);
  }
}

.highlight {
  color: $secondary;
  padding:$baseGap;
}

.btn {
  @extend %animatedTransform;
  background: $secondary;
  border: none;
  color: $light;
  cursor: pointer;
  display: inline-block;
  font-size: rem($baseFontSize);
  line-height: rem($baseLineHeight);
  padding: 1rem 2rem;
  text-align: center;
  text-decoration: none;
  font-family: $mainFont;
  border-radius:rem(35px);
  margin:.5rem 0;
  &.grey{
    background:$primary;
  }
  &:hover {
    background: lighten($dark, 15%);
    color: $light;
  }
  &:active {
    background: lighten($dark, 30%);
    color: $light;
  }
  &:focus {
    background: lighten($dark, 45%);
    color: $light;
  }

  *[class^="icon"] {
    margin-left: 0.5rem;
    vertical-align: middle;
  }
}

.clear {
  clear: both;
  float: none;
}

.clearfix {
  @include pie-clearfix;
}

.hidden {
  display: none !important;
}

.hideText {
  @include hideText();
  display: inline-block;
  overflow: hidden;
  width: 0;
}

.floatLeft {
  float: left;
}

img.floatLeft {
  margin-right: 1rem;
}

.floatRight {
  float: right;
}

img.floatRight {
  margin-left: 1rem;
}

.fluid {
  height: auto;
  width: 100%;
}

.nonFluid {
  width: auto !important;
}

.alignLeft {
  text-align: left;
}

.alignRight {
  text-align: right;
}

.alignCenter {
  text-align: center;
}

.wide {
  width: 100%;
  display: block;
}

.distance{
  padding-top:rem(70px);
  padding-bottom:rem(70px);
  @include breakpoint(large){
    padding-top:rem(150px);
    padding-bottom:rem(150px);
  }
}
.distance-bottom{
  padding-bottom:rem(70px);
  @include breakpoint(large){
    padding-bottom:rem(150px);
  }
}
.distance-top{
  padding-top:rem(70px);
  @include breakpoint(large){
    padding-top:rem(150px);
  }
}
.lightGreen{
  background-color:$alert !important;
  background-repeat: no-repeat;
  background-position: bottom left;
  @include responsiveImage('layout/lightGreen.jpg', (tiny, medium), true);

	&.lazyLoaded {
		background-size:75% auto;
	}
}

.count{
  @include breakpoint(large){
    column-count:2;
  }
}

.empty{
  display:none;
}

body.aktivitaeten, body.kids, body.winter, body.events {
  &:not(.cmsBackend){
    main{
      .box, .spbox{
        background:lighten($alert, 5%);
        padding:$baseGap;
        margin:$baseGap 0;
      }
      .spbox{
        @extend .count;
        break-inside: avoid-column;
      }
    }
  }
}
body:not(.cmsBackend){
.abriss{
  a{
    overflow:hidden;
    position:relative;
    margin-bottom:1.5rem;
    display:block;
    img{
      margin-bottom: 0;
    }
    @include breakpoint(medium){
      &:after{
          content:url(../images/layout/berg.svg);
          position:absolute;
          bottom:0;
          width:150%;
          left:50%;
          transform:translateX(-50%) translateY(15px);
        }
      }
    }
  }
} 



/* 16:9 video resolutions */
.videoContainer {
  &:not(.videoTag) {
    position: relative;
    height: 0;
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;

    iframe {
      border: none;
      height: 100%;
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;
    }
  }

  video {
    width: 100%;
    height: auto;
  }
}
.googleMaps {
  @extend .videoContainer;

  @include breakpoint(medium) {
    height: rem(400px);
  }
  @include breakpoint(large) {
    height: 100% !important;
  }
}

body {
  &:before {
    $content: "default:" + $bpContext;
    width: str_length($content) * 6pt + $baseGap * 2;

    @each $point, $width in $gridMap {
      $content: $content + "...." + $point + ":" + nth(map-get(map-get($gridMap, $point), width), 1);

      @include breakpoint($point) {
        width: str_length($content) * 6pt + $baseGap * 2;
      }
    }

    content: "#{$content}";
    display: none !important; /* Prevent from displaying. */
  }

  &.devmode:before,
  &.debug:before {
    background: $alert;
    border-color: $alert;
    border-style: solid;
    border-width: 0 rem($baseGap);
    color: $light;
    display: block !important;
    font-family: Courier;
    font-size: 10pt;
    left: 0;
    line-height: 2.5;
    overflow: hidden;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
    white-space: nowrap;
    z-index: 99999;
  }
  &:hover:before {
    opacity: 0.5;
    pointer-events: none;
  }
}

// Animations
%animatedTransform {
  transition: 0.3s;
}

html.inProgress {
  pointer-events: none;
  &:before {
    content:"";
    width: 100vw;
    height: 100vh;
    background:rgba(0,0,0,0.5);
    position: fixed;
    top:0;
    left:0;
    z-index:99999998
  }
  &:after {
    content:"";
    position: fixed;
    top: 0;
    height: 0;
    background: url('/images/layout/loading.svg') no-repeat center center;
    height: 100vh;
    width: 100vw;
    z-index:99999999;
    filter:invert(100%);
  }
}
.gutscheine-btn {
  text-align: center;
  padding: 5rem 0 ;
}