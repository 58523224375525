
// HEADER
//////////////////////////////
#pageWrap{
	overflow:hidden;
}
body.cmsBackend{
	header, footer, #navigation, .teaser, .curator, .fix{
		display:none;
	}
	div{
		&:not(.lazyLoaded){
			background:none;
		}
	}
}
#header {
	position: relative;
	padding: rem(60px) 0 0 0;
	width: 100%;
	min-height: rem(400px);
	background-position: center;
	@include breakpoint(tiny){
		padding: rem(60px) rem($baseGap) 0 rem($baseGap);
	}
	body:not(.index) &{
		min-height: rem(320px);
	}
	.distance{
		body:not(.index) &{
			padding:$baseGap 0;
		}
	}
		.row{
			z-index:5;
		}
		&:before{
			content:url(../images/layout/berg.svg);
			position:absolute;
			bottom:0;
			width:100%;
			left:0;
			transform:translateY(10px);
			z-index:3;
		}
		@include breakpoint(full, max){
			&:after{
				content:'';
				width:100%;
				height:100%;
				background:$dark;
				z-index:2;
				opacity:.5;
				position:absolute;
				top:0;
				left:0;
			}
		}
		body:not(.index) &{
			&:after{
				content:'';
				width:100%;
				height:100%;
				background:$dark;
				z-index:2;
				opacity:.5;
				position:absolute;
				top:0;
				left:0;
			}
		}

	@include responsiveImage('layout/bgHeader.jpg', (tiny, medium), false);

	&.lazyLoaded {
		background-size:cover
	}
	svg{
		transform:translateY(10px)
	}
	@include breakpoint(medium) {
		padding: 8rem $baseGap 0 $baseGap;
	}

	.branding {
		display: block;
		max-width: 200px;
		width: 100%;
		height: auto;
		margin: 0 auto;
		position: relative;
		z-index: 100;
	}

	picture {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 0;
		width: 100%;
		height: auto;
	}
	.fix{
		.details{
			display:none;
			li{
				&:before{
					@extend .icon;
					padding-right:.5rem;
				}
				&:first-child{
					&:before{
						@extend .icon-phone;
					}
				}
				&:nth-child(2){
					a{
						color:$light;
						&:hover{
							opacity:.7;
						}
					}
					&:before{
						@extend .icon-paper-plane;
					}
				}
				&:nth-child(3){
					a{
						color:$light;
						text-decoration: none;
						&:hover{
							opacity:.7;
						}
					}
					&:before{
						@extend .icon-facebook;
					}
				}
			}
		}
		@include breakpoint($break){
			position: fixed;
			top:0;
			left:50%;
			transform:translateX(-50%);
			z-index:100;
			&.in{
				.branding{
					transform:translateY(-150px);
						opacity:0;
				}
				.details{
					@include breakpoint(full){
						top:1rem;
					}
				}
			}
			.branding{
				position:absolute;
				left:.25rem;
				top:1.5rem;
				overflow:hidden;
				transform:translateX(0);
				@include breakpoint(giant){
					left:$baseGap;
				}
				@include breakpoint(monster){
					top:70px;
				}
			}
			.details{
				position:absolute;
				color:$light;
				display:inline-flex;
				align-items: center;
				top:1rem;
				right:0;
				transition: all .3s ease-in-out;
				li{
					padding:0 $baseGap;
				}
				
				@include breakpoint(monster){
					top:78px;
				}
			}
		}
	}
	.fade{
		animation: fadeTop .5s both .5s linear;
	}
	blockquote{
		color:$light;
		margin-bottom: 2rem;
		span{
			font-size: rem(50px);
			line-height: rem(58px);
			display:block;
			font-family: $displayFont;
		}
		@include breakpoint(large){
			font-size: rem(22px);
			line-height: rem(32px);
			span{
				font-size: rem(98px);
				line-height: rem(105px);
			}
		}
	}
	.btn{
		&:before{
			@extend .icon;
			@extend .icon-calendar;
			padding-right:.5rem;
		}
		&.grey{
			&:before{
				@extend .icon-gift;
				font-size: rem(22px);
				transform:translateY(1px)
			}
		}
	}
}

// MAIN CONTENT
//////////////////////////////

main {
	display: block;

	p,
	ol,
	ul,
	dl,
	table {
		@extend .marginBottom;
	}

	ul:not(.unstyledList),
	dl:not(.unstyledList),
	ol:not(.unstyledList) {
		@extend .styledList;
	}

	img {
		margin-bottom:rem($baseGap*2);
		@extend .nonFluid;
	}
	.icons{
		body:not(.cmsBackend) &{
			@include breakpoint(large){
				position:relative;
				&:before{
					content:'';
					width:rem(235px);
					height:rem(50px);
					background:url('../images/layout/line.svg') no-repeat center center /contain;
					position:absolute;
					top:2rem;
					left:rem(-150px);
					z-index:3;
				}
				&:after{
					content:'';
					width:rem(100px);
					height:rem(100px);
					background:url('../images/layout/haken.svg') no-repeat center center /contain;
					position: absolute;
					top:-2.5rem;
					left:-220px;
				}
			}
		}
	}
	.calendar{
		background:$light;
		padding:2rem 1rem;
		position:relative;
		overflow:hidden;
		
		@include breakpoint(large){
			padding:rem(90px) rem(60px);
			&:before{
				content:url(../images/layout/berg.svg);
				position:absolute;
				bottom:0;
				width:150%;
				left:50%;
				transform:translateX(-50%) translateY(10px);
			}
		}
	}
	.teaser{
		position: relative;
		&.lazyLoaded{
			&:before{
				content:url(../images/layout/berg-light.svg);
				position:absolute;
				top:0;
				width:100%;
				left:0;
				transform:scaleY(-1) translateY(10px);
				z-index:10;
			}
		}
		.thumbs{
			@include breakpoint(medium, max){
				display:none;
			}
		}
		.mySwiper2{
			.background{
				padding:rem(150px) $baseGap rem(150px) $baseGap;
				background:$alert;
				text-align: center;
				background-position: bottom right;
				
				blockquote{
					font-size: rem(70px);
					line-height: rem(78px);
					margin-bottom: 2rem;
					font-family: $displayFont;
					color:$light;
					font-weight: bold;
					position: relative;
					display:block;
					@include breakpoint(huge){
						font-size: rem(150px);
						line-height: rem(160px);
					}
				}
				@include breakpoint(large){
					padding:rem(350px) $baseGap rem(250px) $baseGap;
					blockquote{
						span{
							display: inline-block;
							position: relative;
						}
					}
				}
			}
			&.lazyLoaded {
				.background{
					background-size:cover;
					blockquote{
						span{
							&:before{
								content:'';
								width:rem(200px);
								height:rem(70px);
								background:url('../images/layout/line-teaser.svg') no-repeat center center /contain;
								position:absolute;
								right:0;
								top:3rem;
								transform:translateY(-100%) translateX(95%);
							}
							&:after{
								content:'';
								width:rem(150px);
								height:rem(150px);
								position: absolute;
								right:0;
								top:-1rem;
								transform:translateY(-100%) translateX(175%) rotate(-5deg);
							}
						}
					}
					&.events{
						blockquote{
							span{
								&:after{
									background:url('../images/layout/events-light.svg') no-repeat center center /contain;
								}
							}
						}
					}
					&.aktivitaeten{
						blockquote{
							span{
								&:after{
									background:url('../images/layout/kompass-light.svg') no-repeat center center /contain;
								}
							}
						}
					}
					&.schulen{
						blockquote{
							span{
								&:after{
									background:url('../images/layout/schulen-light.svg') no-repeat center center /contain;
								}
							}
						}
					}
					&.kids{
						blockquote{
							span{
								&:after{
									background:url('../images/layout/kids-light.svg') no-repeat center center /contain;
								}
							}
						}
					}
					&.winter{
						blockquote{
							span{
								&:after{
									background:url('../images/layout/winter-light.svg') no-repeat center center /contain;
								}
							}
						}
					}
				}
			}
		}
		.mySwiper{
			border-right:1px dashed $border;
			&.lazyLoaded{
				.swiper-slide{
					span{
						&.events{
							&:before{
								background:url('../images/layout/events.svg') no-repeat center center /contain;
							}
						}
						&.aktivitaeten{
							&:before{
								background:url('../images/layout/kompass.svg') no-repeat center center /contain;
							}
						}
						&.schulen{
							&:before{
								background:url('../images/layout/schulen.svg') no-repeat center center /contain;
							}
						}
						&.kids{
							&:before{
								background:url('../images/layout/kids.svg') no-repeat center center /contain;
							}
						}
						&.winter{
							&:before{
								background:url('../images/layout/winter.svg') no-repeat center center /contain;
							}
						}
					}
					&.swiper-slide-thumb-active{
						background:$secondary;
						color:$light;
						span{
							&.events{
								&:before{
									background:url('../images/layout/events-light.svg') no-repeat center center /contain;
								}
							}
							&.aktivitaeten{
								&:before{
									background:url('../images/layout/kompass-light.svg') no-repeat center center /contain;
								}
							}
							&.schulen{
								&:before{
									background:url('../images/layout/schulen-light.svg') no-repeat center center /contain;
								}
							}
							&.kids{
								&:before{
									background:url('../images/layout/kids-light.svg') no-repeat center center /contain;
								}
							}
							&.winter{
								&:before{
									background:url('../images/layout/winter-light.svg') no-repeat center center /contain;
								}
							}
						}
					}
				}
			}
			.swiper-slide{
				border-left:1px dashed $border;
				span{
					display:block;
					padding:rem(120px) 1rem 1rem 1rem;
					font-size: rem(22px);
					text-align: center;
					line-height: rem(32px);
					position: relative;
					cursor: pointer;
					&:before{
						content:'';
						width:rem(100px);
						height:rem(90px);
						background:$dark;
						padding-right:.5rem;
						display:block;
						position: absolute;
						left:50%;
						top:1rem;
						transform: translateX(-50%);
					}
					@include breakpoint(full){
						padding:3rem 3rem 3rem rem(175px);
						&:before{
							padding-right:.5rem;
							left:2rem;
							top:50%;
							transform:translateY(-50%) translateX(0);
						}
					}
				}
			}
		}
		&.lazyLoaded{
			.mySwiper2{
				.background{
					&.events{
						@include responsiveImage('index/bgEvents.jpg', (tiny, medium), false);	
					}
					&.aktivitaeten{
						@include responsiveImage('index/bgAktivitaeten.jpg', (tiny, medium), false);	
						background-position: center;
					}
					&.schulen{
						@include responsiveImage('index/bgSchulen.jpg', (tiny, medium), false);	
					}
					&.kids{
						@include responsiveImage('index/bgKids.jpg', (tiny, medium), false);	
					}
					&.winter{
						@include responsiveImage('index/bgWinter.jpg', (tiny, medium), false);	
					}
				}
			}
		}
	}
	.eventsHandler{
		background:$secondary;
		color:$light;
		padding:$baseGap;
		margin-bottom: 0;
		font-size: 22px;
		letter-spacing: 0;
	}
	.eventsContainer{
		background:$alert;
		padding:1.5rem;
		margin-bottom:3rem;
		.col{
			margin:$baseGap 0;
			.productpage-date-time{
				height:100%;
				span{
					padding:.5rem $baseGap;
					
					&.eventdate, &.eventday, &.eventtime, &.eventpersons, &.eventrequirements, &.infos{
						display:block;
						font-weight: normal;
						
						span{
							padding:0;
							font-weight: bold;
							display:block;
						}
					}
					&.eventday, &.eventpersons, &.infos{
						background:darken($alert, 5%);
					}
					&.eventdate, &.eventtime, &.eventrequirements{
						background:lighten($alert, 10%);
					}
					&.infos{
						position: relative;
						padding:$baseGap $baseGap $baseGap rem(80px);
						&:before{
							content:'i';
							background:$dark;
							color:$light;
							padding:0rem .5rem;
							margin-right: .5rem;
							height:30px;
							width:rem(30px);
							font-weight: bold;
							display:flex;
							align-items: center;
							justify-content: center;
							position: absolute;
							border-radius:50%;
							left:rem(33px);
							top:50%;
							z-index: 2;
							transform:translate(-50%, -50%);
						}
						&:after{
							content:'';
							background:$light;
							height:rem(66px);
							width:rem(66px);
							position: absolute;
							left:0;
							top:0;
							z-index:1;
						}
					}
				}
			}
			.price{
				background:orange;
				color:$light;
				padding:$baseGap $baseGap $baseGap rem(80px);
				font-weight: bold;
				position: relative;
				@include breakpoint(large){
					font-size: rem(22px);
				}
				&:before{
					content:'€';
					background:$light;
					color:orange;
					padding:0rem .5rem;
					margin-right: .5rem;
					height:100%;
					width:rem(66px);
					display:flex;
					align-items: center;
					justify-content: center;
					position: absolute;
					left:0;
					top:0;
				}
			}
			.priceInfo{
				background:lighten(orange, 10%);
				padding:$baseGap;
				color:$light;
				margin-bottom: 1.5rem;
			}
		}
	}
	.curator{
		background:$secondary;
		background-repeat: no-repeat;
		background-position: bottom right;
		@include breakpoint(medium){
		@include responsiveImage('layout/bgFacebook.jpg', (tiny, medium), true);
		}
		position: relative;
		&.lazyLoaded{
			background-size: contain;
			&:before{
				content:url(../images/layout/berg-blau.svg);
				position:absolute;
				top:0;
				width:100%;
				left:0;
				transform: scaleX(-1) translateY(-82%);
				@include breakpoint(large){
					transform: scaleX(-1) translateY(-85%);
				}
				@include breakpoint(huge){
					transform: scaleX(-1) translateY(-93%);
				}
			}
			h2{
				span{
					@include breakpoint(large){
						&:before{
							content:'';
							width:rem(200px);
							height:rem(70px);
							background:url('../images/layout/line-teaser.svg') no-repeat center center /contain;
							position:absolute;
							right:0;
							top:1rem;
							transform:translateY(-100%) translateX(95%) scaleY(-1) rotate(-120deg);
						}
						&:after{
							content:'';
							width:rem(150px);
							height:rem(150px);
							position: absolute;
							right:-320px;
							top:0rem;
							transform:translateY(-100%)  rotate(-5deg);
							background:url('../images/layout/brille.svg') no-repeat center center /contain;
							opacity:.8;
						}
					}
				}
			}
		}

		h2{
			margin-bottom: 5rem;
			display: block;
			width: 100%;
			span{
				color:$light;
				font-size: rem(42px);
				font-weight: normal;
				position: relative;
				display:inline-block;
				@include breakpoint(medium){
					font-size: rem(82px);
				}
			}
		}
		.crt-feed-window{
			margin-bottom: 3rem;
		}
		.crt-load-more-container{
			text-align: left;
		}
		#curator-feed-26519216-sports-outdoorguidede-layo{
			width:100%;
			display: block;
		}
		.crt-load-more, .btn{
			background:$light;
			border-radius: 35px;
			color:$secondary;
			border:none;
			text-transform: lowercase;
			margin-bottom: 1rem;
			&.uppercase {
				text-transform:none;
			}
		}
		.fb{
			color:$light;
			position: relative;
			&:before{
				@extend .icon-facebook;
				@extend .icon;
				padding-right:.5rem;
			}
			a{
				color:$light;
				text-decoration: underline;
				&:hover{
					text-decoration: none;
				}
			}
		}
	}
	body.agb &{
		.navi{
			li{
				padding-right:.5rem;
				padding-bottom:.5rem;
				float:left;
				a{
					@extend .btn;
				}
			}
		}
	}
	.termin{
		width:100%;
		background:$light;
		margin-bottom: 1.5rem;
		padding:1.5rem;
		.coursename{
			font-family: $displayFont;
			color:$secondary;
			font-size: rem(32px);
			line-height: rem(40px);
			font-weight: bold;
			margin-bottom: 1rem;
		}
		img{
			margin-bottom: 1.5rem;
		}
		.date-time{
			background:$medium;
			padding:$baseGap $baseGap $baseGap rem(68px);
			position:relative;
			margin-bottom: 1.5rem;
			&:before{
				@extend .icon;
				@extend .icon-calendar;
				position: absolute;
				width:rem(50px);
				height:100%;
				display:flex;
				align-items: center;
				justify-content: center;
				background:$alert;
				top:0;
				left:0;
			}
		}
		.status{
			background:orange;
			color:$light;
			font-weight: bold;
			padding:$baseGap;
			margin-bottom: 1.5rem;
			&:before{
				content:'i';
				background:$light;
				width:rem(30px);
				height:rem(30px);
				display:inline-flex;
				align-items: center;
				justify-content: center;
				color:orange;
				border-radius: 50%;
				margin-right: .5rem;
			}
		}
	}
	body.index &{
		.newsTeaser{
			padding:2rem 0;
			&:not(:last-of-type){
				border-bottom:1px dashed $border;
			}
			.coursename{
				text-transform: uppercase;
				font-weight: bold;
				div{
					font-weight: normal;
				}
			}
		}
	}
	.list{
		background:rgba(255,255,255,.3);
		padding:2rem;
		margin:$baseGap 0;
		p{
			margin-bottom: 0;
		}
	}
	.boxy{
		body:not(.cmsBackend) &{
			width:100%;
			min-height: rem(300px);
			display:block;
			overflow:hidden;
			position: relative;
			margin:$baseGap 0;

			img{
				position: absolute;
				top:0;
				left:0;
				height:100%;
				width:auto;
				max-width: none;
				min-width: 100%;
				z-index:2;
			}
			a{
				position: absolute;
				display:flex;
				align-items: flex-end;
				flex-wrap: wrap;
				z-index:3;
				top:0;
				left:0;
				height:100%;
				width:100%;
				padding:1.5rem;
				font-weight: bold;
				text-transform: uppercase;
				text-decoration: none;
				font-size: rem(20px);
				background:rgba(0,0,0,.6)
			}
		}
	}
	.dcCategoryNav {
		@extend .clearfix;
		@include breakpoint(large, max){
			li{
				float:left;
				display: inline-block;
				margin-right:1rem;
			}
		}
	}
	.dcOverviewItem{
		.box{
			background:rgba(255,255,255,.3);
			padding:$baseGap;
			height:100%;
			h2{
				span{
					font-family: $mainFont;
					font-weight: normal;
					font-size: 20px;
					text-transform: uppercase;
					letter-spacing: 2px;
					line-height: rem(30px);
				}
			}
			a{
				display:block;
				img{
					margin-bottom: 0;
				}
			}
			.dcCategoryList{
				background:$secondary;
				color:$light;
				padding:.5rem;
			}
		}
	}
	.select-product {
		h1{
			font-family: $displayFont;
			letter-spacing: 0;
			color:$secondary;
			font-size: rem(40px);
			line-height: rem(48px);
			text-transform: none;
		}
		.dcCategoryList{
			background:$secondary;
			color:$light;
			padding:.5rem;
			
		}
		input{
			height:68px;
			padding:.5rem;
			
		}
		.btn{
			padding:1rem 1.5rem 1rem 1rem;
			border-radius:0 35px 35px 0;
			background:orange;
			border:1px solid orange;
		}
		.box{
			background:rgba(255,255,255,.3);
			padding:2rem;
		}
		.dcImageCol{
			margin:$baseGap 0;
		}
	}
	table{
		
		&.selectCart{
			thead{
				background:$primary;
				color:$light;
			}
			tr{
				border-bottom: none;
				&:nth-child(even){
					background:rgba(255,255,255,.3);
				}
				img{
					margin-bottom: 0;
				}
				input{
					padding:.5rem !important;
				}
			}
		}
	}
	.video{
		@include responsiveImage('layout/bgVideo.jpg', (tiny, medium), true);
		background-position: center;

		&.lazyLoaded {
			background-size:cover;
			blockquote{
				span{
					color:$light;
					font-size: rem(50px);
					line-height: rem(58px);
					font-family: $displayFont;
					text-transform: none;
					position: relative;
					display:inline-block;
					@include breakpoint(giant){
						&:before{
							content:'';
							width:rem(200px);
							height:rem(70px);
							background:url('../images/layout/line-teaser.svg') no-repeat center center /contain;
							position:absolute;
							right:0;
							top:1rem;
							transform:translateY(-100%) translateX(95%) scaleY(-1) rotate(-120deg);
						}
						&:after{
							content:'';
							width:rem(125px);
							height:rem(125px);
							position: absolute;
							right:-300px;
							top:0rem;
							transform:translateY(-100%)  rotate(-5deg);
							background:url('../images/layout/bagpack.svg') no-repeat center center /contain;
							opacity:.8;
						}
					}
					@include breakpoint(large){
						font-size: rem(80px);
						line-height: rem(88px);
					}
				}
			}
		}
		.btn{
			&:before{
				@extend .icon;
				@extend .icon-play;
				padding-right:.5rem;
			}
		}
	}
}

#privacyPolicy
{
	a
	{
		display: inline-block; 
		word-break: break-all;
	}
}

// FOOTER
//////////////////////////////

#footer {

	.mountain{
		position: relative;
		padding-bottom:8rem;
		&.lazyLoaded{
			&:before{
				content:url(../images/layout/berg.svg);
				position:absolute;
				bottom:0;
				width:100%;
				left:0;
				transform:translateY(10px);
			}
		}
		.maps{
			.box{
				background:$alert;
				padding:2rem;
				height:100%;
				display:flex;
				justify-content: center;
				flex-direction: column;
				h4{
					color:$secondary;
					font-family: $displayFont;
					font-size: rem(30px);
					line-height: rem(38px);
					margin-bottom: 1rem;
				}
				p{
					margin-bottom: 1.5rem;
				}
				ul{
					li{
						span{
							@include breakpoint(tiny, max){
								display:block;
							}
							@include breakpoint(tiny){
								&.fixWidth{
									width:rem(80px);
									display:inline-block
								}
							}
						}
					}
				}
			}
		}
	}
	.lightGreen{
		background:$alert;
		@extend .distance;
		.sitemap{
			@include breakpoint($break){
				display:flex;
				justify-content: space-between;
			}
			ul{
				li{
					margin-bottom: .5rem;
					a{
						font-weight: bold;
						text-decoration: none;
						line-height: rem(32px);
					}
					ul{
						li{
							margin-bottom: 0;
							a{
								font-weight: normal;
							}
							ul{
								li{
									a{
										padding-left:1rem;
										position: relative;
										&:before{
											content:'';
											height:1px;
											width:.5rem;
											border-bottom:1px dashed $primary;
											position:absolute;
											left:0;
											top:50%;
											transform:translateY(-50%);
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}



@keyframes fadeTop {
	0% {
	  opacity:0;
	  transform:translateY(100px);
	}
	100% {
		opacity:1;
		transform:translateX(0);
	}
}
.animate{
	body.javascript & {
		.first, .second{
			opacity:0;
		}
		&.show{
			.first{
				animation: fadeTop .5s both .5s linear;
			}
			.second{
				animation: fadeTop .5s both .7s linear;
			}
		}
	}
}

.firstContent{
	body.javascript & {
		animation: fadeTop .5s both .5s linear;
	}
}

.smallerHeight {
	@include breakpoint(large) {
		height: 96% !important;
	}
}