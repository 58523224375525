.selectProductList
{
    margin-bottom: 6rem;
}

.selectSidebarCategories
{
	margin-bottom: $baseGap*2;
	
	a
	{
		margin-right: $baseGap;
		margin-bottom: $baseGap;

		&.active
		{
			background:$secondary;
			color:$primary;
		}
    }
    
    .selectSidebarTitle
    {
        font-weight:bold;
        font-size:110%
    }
}

.selectOverviewItem
{
	position: relative;
    text-align:left;
    padding-bottom:rem($baseGap/2);
    
    .categories
    {
        text-transform: uppercase;
        font-size:rem(12px);
        margin-bottom: rem($baseGap/2);

        a
        {
            text-decoration: none;
            position: relative;

            &:after
            {
                position: absolute;
                height:rem(2px);
                width: 100%;
                content:"";
                display: inline-block;
                left: 0;
                bottom: -3px;
                background: $medium;
            }
        }

        .divider
        {
            color:$secondary;
        }
    }

    .title
    {
        text-decoration: none;
        font-size:$h2Size;
        display: block;
        margin-top: rem($baseGap);
        margin-bottom: rem($baseGap);
    }

    .description
    {
        text-decoration: none;
        display: block;
        color:$primary;
        margin-bottom: $baseGap;
        text-align: left;
        font-size:rem(14px);
        line-height:rem(20px);
    }

    time
    {
        font-size:rem(12px);
        display: block;
    }

    .btn
    {
        font-size:rem(12px);
        &.small
        {
            padding:rem($baseGap/3)
        }
    }

    .price
    {
        text-align: right;
        font-weight: bold;
        font-size:rem(20px)
    }

    small
    {
        font-size:rem(10px);
        line-height:rem(11px);
        display: block;
        text-align: right;
        margin-bottom: rem(10px);
    }
}


.selectProductDetails
{
    h1
    {
        margin-bottom: $baseGap;
    }
    time
    {
        display: block;
        text-align: center;
        // margin-bottom: 3rem;
    }

    .categories
    {
        text-align: center;
        margin-bottom: 3rem;
    }

    .price
    {
        margin-bottom: 0;
        i
        {
            font-size:rem(14px);
        }
    }

    .selectProductActions
    {
        justify-content: flex-end;
        display: flex;
    }
}

.selectProductGallery
{
    margin-top: 3rem;

    .gridItem
    {
        margin-bottom: rem($baseGap);
    }
}

#selectCart
{
    td,th
    {
        vertical-align: middle;
        small
        {
            display: block;
        }
    }
    thead
    {
        text-transform: uppercase;
        font-size:12px;
        letter-spacing:1px;
        color:lighten($dark, 50%);
    }
    tfoot
    {
        border-style:double;
        border-left:none;
        border-right:none;
        border-top:2px double lightgrey;
        border-bottom:2px double lightgrey;
    }
    img:not(#cartSpinner)
    {
        max-width:50px;
        height: auto;
        margin-right: rem($baseGap);
        vertical-align:middle;
    }

    #cartSpinner
    {
        max-width:16px;
        max-height:16px;
        width:100%;
        height:auto
    }

    .changeQuantity + #cartSpinner
    {
        margin-left: 5px;
    }

    a.details
    {
        display:flex;
        flex-direction: row;
        align-items: center;
    }

    input[type=number]
    {
        border:1px solid #ccc;
        padding:3px;
        width: 100% !important;
    }

    // p 
    // {
    //     margin-bottom: 0;
    // }

    .remove
    {
        font-size:rem(22px);
        cursor: pointer;
    }

    td {
        min-width:auto;
        max-width:75px;

        &.selectCartMainCol {
            min-width: em(200px);
            max-width:none
        }

    }
}

.selectInstruction
{
    font-style:italic;
    color:$alert;
    font-size:rem(12px);
    line-height: rem(16px);
}

.selectMessage
{
    background: darken($alert, 15%);
    color:$light;
    padding:rem($baseGap) rem($baseGap) 0 rem($baseGap);
    margin-bottom: 2rem;

    p
    {
        margin-bottom: 0;
        padding-bottom:rem($baseGap);
    }
}

.selectIndicator
{
    position: fixed;
    right: 1rem;
    bottom: 3.5rem;
    transform:translateY(-50%);
    padding:rem(12px);
    background: orange;
    color:$dark;
    display: inline-block;
    border-radius: 50%;
    height:rem(54px);
    width:rem(54px);

    img
    {
        width:24px;
        height:24px;
        margin:0
    }
}
[data-cart-count]
{
    &:after
    {
        position: absolute;
        content:attr(data-cart-count);
        font-size:10px;
        top: 10px;
        right: 10px;
        background: darken(orange, 5%);
        border-radius:50%;
        height:14px;
        line-height:14px;
        padding:0 5px;
        color:$light;
    }
}
a[data-cart-count]
{
    position: relative;
}

#selectCartDeliveryAddress
{
    box-shadow: 0px 0px 18px 1px rgba(0, 0, 0, 0.1);
    background:#fff;

    &.disabled {
		input, textarea, select, button, label {
			pointer-events: none;
			opacity:0.5;

			a
			{
				pointer-events: all;
			}
		}
	}

    fieldset
    {
        padding:rem($baseGap*2);
        margin-bottom: rem($baseGap*2);
        border:none;

        legend
        {
            display:block;
            font-size:rem(20px);
            font-weight:bold;
            margin-bottom: rem($baseGap);
            padding-bottom: rem($baseGap/2);
            border-bottom:1px dashed #ddd;
        }
    }

    .collapsed
    {
        display: block;
        width: 100%;
    }

    #invoiceAsDelivery:checked ~ .collapsed
    {
        display:none;
    }

    label, .label
    {
        display: block;
        margin-bottom: rem($baseGap/2);
        font-weight:bold;
        
        &:not(.radio)
        {
            margin-top: rem($baseGap);
        }

        &.radio
        {
            width:auto;
        }
        small
        {
            display: inline-block;
        }
    }
    input[type=text],
    input[type=email],
    input[type=number]
    {
        width: 100%;
        height:rem(40px);
        line-height:rem(40px);
        padding:0 rem($baseGap);
        border:2px solid #eee;
        // margin-bottom: rem($baseGap);

        &:focus
        {
            border-color:#ddd;
        }
    }
    input[type=radio], input[type=checkbox]
    {
        float:left;
        margin-right: rem($baseGap);
        margin-top: 6px;
    }
}

.selectCartTitle
{
    font-size:rem(34px);
    line-height:rem(40px);
    // font-weight:bold;
    margin-bottom: rem($baseGap*3);
}

#selectAside
{

    .selectCartTitle
    {
        font-size:rem(28px);
    }

    dl
    {
        border-top:2px solid #eee;
        padding-top:rem($baseGap);   
        display: flex;
        flex-wrap:wrap;

        dt:first-of-type
        {
            font-weight:bold;
            text-transform: uppercase;
        }

        dt,dd
        {
            width: 50%;
            margin:0;
            padding:0;
            background: transparent;
        }
        dd
        {
            text-align: right;
            font-weight:bold
        }

        .lighter
        {
            color:lighten($primary,50%);
        }

        .desc
        {
            font-size:rem(12px);
        }

        &.costs
        {
            dt,dd
            {
                margin-bottom: rem($baseGap/3);
                font-weight:normal;
                text-transform: none;
            }
            .fat
            {
                font-size:110%;
                font-weight:bold
            }
        }

    }
}

#selectBreadcrumb
{
    background: #eff2f9;
    display:flex;
    justify-content: center;
    padding:1.5rem 0;
    overflow: hidden;

    li
    {
        position: relative;
        padding-top:rem(20px);
        margin-right: $baseGap*2;
        color:#a7adbc;
        font-weight: normal;

        &:before
        {
            position: absolute;
            top: 0;
            left:50%;
            transform:translateX(-50%);
            border-radius:50%;
            border:2px solid #dce2ee;
            background: #dce2ee;
            width:14px;
            height:14px;
            content:"";
            z-index:2;
        }
        &.current
        {
            &:before
            {
                background: #fdfefe;
                border-color:#4977f4;
            }
        }
        &.done
        {
            &:before
            {
                background: #4977f4;
                border-color:#4977f4
            }
            &:after
            {
                background: #4977f4;
            }
        }

        &:after
        {
            position: absolute;
            top: 0;
            left:50%;
            transform:translateY(5px);
            width:1000px;
            content:"";
            background: #dce2ee;
            height:3px;
            z-index:1;
        }
        &:last-child:after
        {
            background: #eff2f9;
        }
    }
}

#selectNotes
{
    position: fixed;
    bottom: 1rem;
    left:1rem;
    
    .notification
    {
        background: $alert;
        padding:$baseGap;
    }
}


body.xhrInProgress
{
    cursor: progress;

    #content a
    {
        pointer-events: none;
    }
}


#selectSubmitFields
{
    display:flex;
    justify-content: space-between;

    #selectCheckoutSubmit
    {
        order:3
    }
}
.selectProductActions {
    display: flex;
    min-height: rem(30px);
    // flex-direction: column;

    .raiseQuantity {
        width:rem(40px);
        display: block;
        border:1px solid $primary;
        text-align: center;
    }

    .btn {
        word-wrap: none;
        word-break: keep-all;
    }
}

.selectCartAdd
{
    position: relative;

    span {
        display: none;

        .selectProductDetails & {

            @include breakpoint(medium) {
                display:inline
            }
        }

    }

    &:before
    {
        content:"";
        background:orange url('/_modules/dc/plugins/select/assets/svg/cart.svg') center center no-repeat;
        background-size:rem(20px);
        width:rem(64px);
        height: 100%;
        display: block;
        .dcOverviewItem &{
            border:1px solid orange;
        }
    }

    &:after
    {
        .dcOverviewItem &{
            display: flex;
            align-items: center;
            justify-content: center;
            content:"+";
            position: absolute;
            top:50%;
            left:50%;
            font-size:rem(12px);
            width:rem(13px);
            height:rem(13px);
            text-align: center;
            background:darken(orange, 10%);
            color:$light;
            border-radius:50%;
            line-height: 10px;
            transform:translate(2px, -12px);
        }
    }
}

